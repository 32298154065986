<template>
  <div class="ma-10">
    <v-form>
      <v-row>
        <v-col cols="12">
          <form-step-title title="Contraintes et risques associés pour l’hébergement informatique actuel" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-fields-slider
            v-model="risque_connectivite"
            min="0"
            max="10"
            field-label="Connectivité inter sites (réseau)"
            class="mt-6"
            hint="Votre hébergement est-il suffisamment connecté? Nombre de liens ? Débit? Opérateurs?"
            persistent-hint
            :tick-labels="tickLabels"
            :currency="false"
            @change="saveFormAnswer"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-fields-slider
            v-model="risque_financier_juridique"
            min="0"
            max="10"
            field-label="Financier / Juridique"
            hint="Votre hébergement est-il lié à des contrats externes? pas suffisamment maitrisés? courte durée? à risque? sujet à des variations tarifaires importantes ? un bail foncier arrivant à échéance ? "
            persistent-hint
            class="mt-6"
            :tick-labels="tickLabels"
            :currency="false"
            @change="saveFormAnswer"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-fields-slider
            v-model="risque_gouvernance"
            min="0"
            max="10"
            field-label="Gouvernance"
            hint="La gouvernance associée à la gestion et l'exploitation de votre hébergement actuel est-elle adaptée ? à risque ?"
            persistent-hint
            class="mt-6"
            :tick-labels="tickLabels"
            :currency="false"
            @change="saveFormAnswer"
          />
        </v-col>
      </v-row>
      <form-fields-radio-group 
        v-model="dispo_informatique_choice"
        field-label="Disponibilité informatique : test d'interruption" 
        :radio-data-list="booleanList"
        hint="Redondance/résilience système et réseau. 1 test d'interruption fait chaque année pour vérifier le bon fonctionnement."
        persistent-hint
        class="mb-2"
        @change="saveFormAnswer"
      />
      <form-fields-radio-group 
        v-model="salle_serveurs_redondance_complete_choice"
        field-label="2ème salle serveurs : redondance complète de la première salle" 
        :radio-data-list="booleanList"
        class="mb-2"
        @change="saveFormAnswer"
      />
      <form-fields-radio-group 
        v-if="salle_serveurs_redondance_complete_choice !== 'Oui'"
        v-model="salle_serveurs_redondance_services_critiques_choice"
        field-label="Redondance uniquement des services critiques" 
        :radio-data-list="booleanList"
        class="mb-2"
        @change="saveFormAnswer"
      />
      <v-row class="mt-3 mb-6">
        <v-col cols="12">
          <form-fields-slider
            v-model="sauvegarde_SI"
            min="0"
            max="10"
            :tick-labels="tickLabels"
            field-label="Plan de sauvegarde du SI" 
            hint="Un plan de sauvegarde du SI consiste en la capacité à réaliser les sauvegardes, tester les restaurations à une fréquence conforme à son activité : quotidienne, hebdomadaire, mensuelle."
            persistent-hint
            class="mt-6"
            :currency="false"
            @change="saveFormAnswer"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-step-title title="Référentiels de sécurité pour votre activité" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-fields-radio-group
            v-model="certifie_iso_27001"
            class="mb-4 mt-3"
            field-label="ISO 27001" 
            hint="Etes-vous certifié ?"
            persistent-hint
            :radio-data-list="formAnswerEnums.extended_choice_options"
            simple-radio-button
            @change="saveFormAnswer"
          />
          <form-fields-radio-group
            v-model="certifie_hds"
            class="mb-4"
            field-label="HDS" 
            hint="Etes-vous certifié ?"
            persistent-hint
            :radio-data-list="formAnswerEnums.extended_choice_options"
            simple-radio-button
            @change="saveFormAnswer"
          />
          <form-fields-radio-group
            v-model="certifie_rgs"
            class="mb-4"
            field-label="RGS"
            hint="Respectez-vous les prérogatives du Référentiel général de sécurité  ?"
            persistent-hint
            :radio-data-list="formAnswerEnums.complexe_choice_options"
            simple-radio-button
            @change="saveFormAnswer"
          />
          <form-fields-radio-group
            v-model="certifie_pssie"
            class="mb-4"
            field-label="PSSIE" 
            hint="Disposez-vous d'une Politique de Sécurité des Sytèmes d'Information de l'Etat ?"
            persistent-hint
            :radio-data-list="formAnswerEnums.extended_choice_options"
            simple-radio-button
            @change="saveFormAnswer"
          />
          <form-fields-radio-group
            v-model="rgpd_presence_dpo_dpd"
            class="mb-4"
            field-label="RGPD" 
            hint="Avez-vous un DPO/DPD pour votre structure ? (DPO= Data Protection Officer / DPD= Délégué à la Protection des Données)"
            persistent-hint
            :radio-data-list="formAnswerEnums.simple_choice_options"
            simple-radio-button
            @change="saveFormAnswer"
          />
          <v-row class="justify-end">
            <v-col cols="8">
              <form-fields-radio-group
                v-model="rgpd_renseignement_registre_traitement"
                class="mb-4"
                hint="Avez-vous renseigné le registre des traitements pour votre direction ?"
                persistent-hint
                :radio-data-list="formAnswerEnums.simple_choice_options"
                simple-radio-button
                @change="saveFormAnswer"
              />
            </v-col>
          </v-row>
          <v-row class="justify-end">
            <v-col cols="8">
              <form-fields-radio-group
                v-model="rgpd_renseignement_registre_sous_traitance"
                class="mb-4"
                hint="Avez-vous renseigné le registre de sous-traitance pour votre direction ?"
                persistent-hint
                :radio-data-list="formAnswerEnums.simple_choice_options"
                simple-radio-button
                @change="saveFormAnswer"
              />
            </v-col>
          </v-row>
          <form-fields-radio-group
            v-model="certifie_ebios"
            class="mb-4"
            field-label="EBIOS" 
            hint="Avez-vous mené une étude d'Expression des Besoins et Identification des Objectifs de Sécurité ?"
            persistent-hint
            :radio-data-list="formAnswerEnums.extended_choice_options"
            simple-radio-button
            @change="saveFormAnswer"
          />
          <form-fields-text
            v-model="nombre_mesures_ansii_reco"
            field-label="Combien de mesures recommandées par l'ANSII sont mises en oeuvre au sein du SI ?" 
            placeholder="Nombre de mesures recommandées"
            type="number"
            number-integer
            @change="saveFormAnswer"
          />
          <form-fields-radio-group
            v-model="respect_charte_local_securite"
            class="mb-4"
            field-label="Chartes locales de sécurité" 
            hint="Respectez-vous une/des charte(s) locale(s) de sécurité  ?"
            persistent-hint
            :radio-data-list="formAnswerEnums.complexe_choice_options"
            simple-radio-button
            @change="saveFormAnswer"
          />
          <form-fields-radio-group
            v-model="certifie_tier_uptime_institute"
            class="mb-4"
            field-label="Tier uptime Institute" 
            hint="Etes-vous certifié?"
            persistent-hint
            :radio-data-list="formAnswerEnums.extended_choice_options"
            simple-radio-button
            @change="saveFormAnswer"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-step-title title="Plan de secours" />
        </v-col>
      </v-row>
      <form-fields-text
        v-model="delai_garantie_retablissement"
        field-label="Quel est le délai de la garantie de rétablissement (GTR) pour les briques critiques du SI ?" 
        placeholder="Durée en heures"
        type="number"
        number-integer
        class="mt-6"
        @change="saveFormAnswer"
      />
      <form-fields-radio-group
        v-model="plan_reprise_continuite_activite_ok"
        field-label="Avez-vous un plan de reprise/continuité d'activité ?" 
        :radio-data-list="formAnswerEnums.simple_choice_options"
        simple-radio-button
        @change="saveFormAnswer"
      />
      <form-fields-text
        v-if="plan_reprise_continuite_activite_ok === 'Oui'"
        v-model="plan_reprise_continuite_activite_rto"
        field-label="Quel est RTO ?" 
        placeholder="Durée en heures"
        type="number"
        number-integer
        class="mt-6"
        field-description="RTO = Recovery Time Objective"
        info-icon
        input-suffix="heures"
        @change="saveFormAnswer"
        @iconClicked="openModal = true"
      />
      <form-fields-text
        v-if="plan_reprise_continuite_activite_ok === 'Oui'"
        v-model="plan_reprise_continuite_activite_rpo"
        field-label="Quel est le RPO ?" 
        placeholder="Durée en heures"
        type="number"
        number-integer
        class="mt-6"
        field-description="RPO = Recovery Point Objective"
        info-icon
        input-suffix="heures"
        @change="saveFormAnswer"
        @iconClicked="openModal = true"
      />
      <v-dialog
        ref="dialog"
        v-model="openModal"
        width="800"
        height="800"
      >
        <v-card class="pa-3">
          <v-card-text>
            <v-img 
              src="@/assets/schema-info.gif" 
              alt="schéma explicatif RTO/RPO" 
            />
          </v-card-text>
          <v-card-actions class="justify-end">
            <base-button
              @click="openModal = false"
            >
              Fermer
            </base-button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapState, mapMutations  } from 'vuex';
import FormStepTitle from '@/components/FormStepTitle.vue';
import FormFieldsSlider from '@/components/FormFieldsSlider.vue';
import FormFieldsRadioGroup from '@/components/FormFieldsRadioGroup.vue';
import FormFieldsText from '@/components/FormFieldsText.vue';
import BaseButton from '@/components/BaseButton.vue';

export default {
  name: 'CurrentHost',
  components: {
    FormStepTitle,
    FormFieldsSlider,
    FormFieldsRadioGroup,
    FormFieldsText,
    BaseButton
  },
  data() {
    const tickLabels = ['Pas de risque'];
    tickLabels[10] = 'Très risqué';
    const hebergementTickLabels = ['Pas critique'];
    hebergementTickLabels[10] = 'Très critique';
    const capaHebergementTickLabels = ['Convient totalement'];
    capaHebergementTickLabels[10] = 'Ne convient pas du tout';
    return {
      tickLabels,
      hebergementTickLabels,
      capaHebergementTickLabels,
      openModal: false,
      booleanList: [{
          label: 'Oui',
          value: 'Oui',
        }, {
          label: 'Non',
          value: 'Non',
        }, {
          label: 'Ne sait pas',
          value: 'Ne sait pas'
        }
      ],
    }
  },
  computed: {
    ...mapState('form', ['formAnswerEnums']),
    ...mapFields('form', [
      'formAnswer.risque_connectivite',
      'formAnswer.risque_niveaux_de_service',
      'formAnswer.risque_financier_juridique',
      'formAnswer.risque_criticite',
      'formAnswer.risque_gouvernance',
      'formAnswer.risque_capacite_hebergement',
      'formAnswer.certifie_iso_27001',
      'formAnswer.certifie_hds',
      'formAnswer.certifie_rgs',
      'formAnswer.certifie_pssie',
      'formAnswer.certifie_ebios',
      'formAnswer.certifie_anssi',
      'formAnswer.respect_charte_local_securite',
      'formAnswer.certifie_tier_uptime_institute',
      'formAnswer.nombre_mesures_ansii_reco',
      'formAnswer.delai_garantie_retablissement',
      'formAnswer.plan_reprise_continuite_activite_ok',
      'formAnswer.plan_reprise_continuite_activite_rto',
      'formAnswer.plan_reprise_continuite_activite_rpo',
      'formAnswer.rgpd_presence_dpo_dpd',
      'formAnswer.rgpd_renseignement_registre_traitement',
      'formAnswer.rgpd_renseignement_registre_sous_traitance',
      'formAnswer.dispo_informatique_choice',
      'formAnswer.salle_serveurs_redondance_complete_choice',
      'formAnswer.salle_serveurs_redondance_services_critiques_choice',
      'formAnswer.sauvegarde_SI',
    ]),
  },
  created() {
    this.fetchFormAnswer();
    this.setNextView('/ressources/host');
    this.fetchFormAnswersEnums();
  },
  methods: {
    ...mapMutations('stepper', ['setNextView']),
    ...mapActions('form', ['fetchFormAnswer', 'saveFormAnswer', 'fetchFormAnswersEnums']),
  } 
};
</script>